html,
body,
header,
#intro {
    height: 100%;
}
#intro {
    background: url("./2.jpeg")no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

}
.h4{
    color:red
}