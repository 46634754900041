.top-nav-collapse {
    background-color: transparent
}

@media (max-width: 768px) {
    .navbar:not(.top-nav-collapse) {
        background-color: transparent;
    }
}

@media (min-width: 800px) and (max-width: 850px) {
    .navbar:not(.top-nav-collapse) {
        background-color: transparent;
    }
}
.navbar-brand {
    color: purple;
}
