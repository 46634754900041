body {
    background-color: #E0E3E6;
}

.card.booking-card.v-2 {
    background-color: #E0E3E6;
    
}

.card.booking-card.v-2 .fa {
    color: #f7aa00;
}

.card.booking-card.v-2 .card-body .card-text {
    color: #020202;
}

.card.card.booking-card.v-2 .chip {
    background-color: #E0E3E6;
}

.card.booking-card.v-2 .card-body hr {
    border-top: 1px solid #f7aa00;
}
.row.display-flex {
    display: flex;
    flex-wrap: wrap;
}

.row.display-flex>[class*='col-'] {
    display: flex;
    flex-direction: column;
}


.col-limited {
    max-width: 50%;
    /* Adjust as needed */
}
@media screen and (max-width: 600px) {
    .col-limited {
        max-width: none;
    }
}
